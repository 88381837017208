import { useCallback } from "react";
import { useFrasario } from "../contexts/FrasarioProvider";
import { useShips } from "../contexts/ShipsProvider";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { useTheme } from "../contexts/ThemeProvider";

import iside_icon from "../img/new/iside-logo-ships.png";
import anchor_icon from "../img/icons/anchor.png";
import anchor_icon_dark from "../img/icons/anchor_dark.png";

export default function Message({ message, isLast, shipName, isBaseStation, iside }) {
  const { isDarkTheme } = useTheme();
  const { parseMessage } = useFrasario();
  const parsedMessage = parseMessage(message);
  const { t }= useTranslation();
  const scrollToLastMessage = useCallback(node => {
    if (node == null) return;
    node.scrollIntoView({ smooth: true });
  }, []);

  const { selectedShip } = useShips();

  var messageClass = "sent";
  if (message._id == null) {
    messageClass = "pending";
  } else if (message.received) {
    messageClass = "received";
  }

  return (
    <div ref={isLast ? scrollToLastMessage : null} className={`message ${messageClass}`}>
      <div className="body">
        <div className="ship-icon">
          {isBaseStation && <img src={isDarkTheme ? anchor_icon_dark : anchor_icon} alt=""></img>}
          {(iside && !isBaseStation) && <img src={iside_icon} alt=""></img>}
        </div>
        <div className="text">
          {parsedMessage.text}
        </div>
      </div>
      <div className="info">
        <span>{message.timestamp != null ? moment(message.timestamp).format(t('date_format')) + ", " : ""}</span>
        <span className="ship-name">
          {message.received ?
            (selectedShip.name != null ? selectedShip.name : shipName != null ? shipName : selectedShip.mmsi)
            :
            t('you')
          }
        </span>
      </div>
    </div>
  )
}

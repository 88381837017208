import { MapContainer, TileLayer, Polyline} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import VectorTileLayer from 'react-leaflet-vector-layer';
import CustomMapMarker from './CustomMapMarker';
import MyPositionMarker from './MyPositionMarker';
import ChangeView from './ChangeView';
import { useMap } from '../../contexts/MapProvider';
import { useLocation } from '../../contexts/LocationProvider';
import { useShips } from '../../contexts/ShipsProvider';

export default function MiniMap({ ship }) {
  const { USE_OFFLINE_MAP, MAP_SERVER_URL, OpenMapModal } = useMap();
  const { coords } = useLocation();
  const { selectShipMmsi } = useShips();
  let bounds = null;
  const Leaflet = window.L;
  if (Leaflet != null
    && coords != null
    && ship != null
    && coords[0] != null
    && coords[0] !== ""
    && coords[1] != null
    && coords[1] !== ""
    && ship.latitude != null
    && ship.latitude !== ""
    && ship.longitude != null
    && ship.longitude !== "") {
    bounds = Leaflet.latLngBounds([
      [parseFloat(coords[0]), parseFloat(coords[1])],
      [parseFloat(ship.latitude), parseFloat(ship.longitude)]
    ]);
  }

  return (
    <>
      {
        (ship != null && ship.latitude != null && ship.longitude != null) ?
          <div
            onClick={() => {
              OpenMapModal({
                initialPosition: coords,
                initialBounds: bounds != null
                  && bounds._southWest.lat === bounds._northEast.lat
                  && bounds._southWest.lng === bounds._northEast.lng
                  ? null : bounds,
                interactive: true,
                initialSelection: ship,
                callback: (selectedShip) => {
                  if (selectedShip != null && selectedShip.mmsi != null) {
                    selectShipMmsi(selectedShip.mmsi);
                  }
                }
              })
            }}>
            <MapContainer
              center={[ship.latitude, ship.longitude]}
              scrollWheelZoom={false}
              zoomAnimation= {false}
              zoomControl={false}
              doubleClickZoom={false}
              dragging={false}
              style={{ height: '30vh' }}
              className="border border-2 border-light">
              <ChangeView bounds={bounds} />
              {
                USE_OFFLINE_MAP
                ? <VectorTileLayer styleUrl={`${MAP_SERVER_URL}/styles/iside/style.json`} />
                : <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              }
              <CustomMapMarker
                ship={ship}
              />
              <MyPositionMarker
                defaultPosition={coords}
                interactive={false}
              />
              <Polyline
                positions={[
                  coords,
                  [ship.latitude, ship.longitude]
                ]}
                color={'red'}
                dashArray={'5,10'} />
        
            </MapContainer>
          </div>
        :
          <></>
      }
    </>
  )
}

import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useSettings } from './SettingsProvider';
import { useSocket } from './SocketProvider';

const POSITION_SENDING_INTERVAL = 10000;

const LocationContext = React.createContext();

export function useLocation() {
  return useContext(LocationContext);
}

export function LocationProvider({ children }) {
  const { socket } = useSocket();
  const [position, setPosition] = useState(null);
  const { aisStatus } = useSettings();

  useEffect(() => {
      if (socket == null) return;

      socket.on("position", (newPosition) => {
        console.log("Received position", newPosition);
        if(newPosition !=null){
          setPosition({
            coords: {
              latitude: newPosition.latitude,
              longitude: newPosition.longitude
            },
            speed: {
              kmph: newPosition.speedKmph,
              knots: newPosition.speedKnots
            }
          })
        }      
      });

      return () => socket.off("position")
  }, [socket, setPosition])
  
  useEffect(() => {
    if (aisStatus !== true) {
      console.log("AIS system disabled, retrieving position via browser...");
      // Retrieve current position via browser and send it over MQTT
      if (!navigator.geolocation) {
        console.log("Unsupported Geolocation");
  
      } else {
        const intervalId = setInterval(() => {
          navigator.geolocation.getCurrentPosition((position) => {
            console.log("Retrieved position", position);
            if (position != null && position.coords != null) {
              let newPosition = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                speedKmph: position.coords.speed != null ? position.coords.speed * 3.6 : 0,
                speedKnots: position.coords.speed != null ? position.coords.speed * 1.94384 : 0
              }
              socket.emit("sendMqttPosition", newPosition);
            }    
          }, (error) => {
            console.log("Unable to retrieve your location", error);
          });
        }, POSITION_SENDING_INTERVAL);
        return () => clearInterval(intervalId);
      }
    }
  }, [aisStatus, setPosition, socket])

  const value = {
    position,
    coords: position != null && position.coords != null ? [position.coords.latitude, position.coords.longitude] : null
  }

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  )
}

import { useShips } from "../contexts/ShipsProvider";
import { useTranslation } from 'react-i18next';
import { useSettings } from "../contexts/SettingsProvider";
import { useWidth } from "../contexts/WidthProvider";
import { Modal } from "react-bootstrap";
import NewMessageModal from "../components/NewMessageModal";
import NewAdvancedMessageModal from "../components/NewAdvancedMessageModal";

import iside_icon from "../img/new/iside-logo-ships.png";
import anchor_icon from "../img/icons/anchor.png";
import anchor_icon_dark from "../img/icons/anchor_dark.png";
import mayday_icon from "../img/icons/mayday.png";
import panpan_icon from "../img/icons/panpan.png";
import securite_icon from "../img/icons/securite.png";
import new_message_icon from "../img/icons/panpan_more.png";
import text_icon from "../img/icons/text_blu.png";
import { useState, useEffect } from "react";
import { useConversations } from "../contexts/ConversationsProvider";
import { useTheme } from "../contexts/ThemeProvider";

export default function Ship({ ship, shipName, hasMessages, unreadMessages}) {
  const { selectShipMmsi } = useShips();
  const { t } = useTranslation();
  const { onlyIsideMessage, advancedMessaging } = useSettings();
  const { useMobileLayout } = useWidth();
  const [selectedMessage, setSelectedMessage] = useState(null);
  const { sendMessage } = useConversations();
  const { isDarkTheme } = useTheme();
  
  useEffect(() => {
    if (ship.selected && !hasMessages) {
      const shipListElement = document.querySelector(".ship-list");
      const shipElement = document.querySelector(`#ship-${ship.mmsi}`);
      const activeShipElement = document.querySelector(`.ship.active`);
      const homeElement = document.querySelector(".Home");
      homeElement.style.zIndex = 10;
      const handleScroll = () => {
        let st = shipListElement.scrollTop;
        let ot = shipElement.offsetTop;
        activeShipElement.style.top = `${ot - st}px`;
      }
      handleScroll();
      if (shipListElement == null) return;
      shipListElement.addEventListener("scroll", handleScroll);
      return () => shipListElement.removeEventListener("scroll", handleScroll)
    }

  }, [ship, hasMessages])

  function handleShipClick() {
    selectShipMmsi(ship.mmsi);
    const homeElement = document.querySelector(".Home");
    if (!hasMessages) {
      homeElement.style.zIndex = 10;
    } else {
      homeElement.style.zIndex = 0;
    }
  }

  function closeModal(message) {
    if (message != null) {
      // Sending message to recipient ship
      sendMessage(ship.mmsi, message)
      const homeElement = document.querySelector(".Home");
      homeElement.style.zIndex = 0;
    }
    setSelectedMessage(null);
  }

  const hasNewMessages = unreadMessages != null && unreadMessages > 0;

  /*function setSelectedMessage(message) {
    console.log(message)
  }*/

  function getShipContent() {
    return (
      <>
        <div className="ship-icon">
          {ship.isBaseStation && <img src={isDarkTheme ? anchor_icon_dark : anchor_icon} alt=""></img>}
          {(ship.iside && !ship.isBaseStation) && <img src={iside_icon} alt=""></img>}
        </div>
        <div className="ship-info">
          <span className="ship-name">{ship.name != null ? ship.name : shipName}</span>
          {ship.mmsi != null && <span>{ship.mmsi}</span>}
          {ship.distance != null && <span>{t('distance')}: <span className="ship-distance">{ship.distance.toFixed(2)} Km</span></span>}
        </div>
        {hasNewMessages && <div className="ship-message-counter">{unreadMessages}</div>}
      </>
    );
  }

  return (
    <>
      {ship.selected && !hasMessages &&
        <>
        <div className={`ship active ${!useMobileLayout ? "desktop" : ""}`}>
          {getShipContent()}
          <div className={`conversation-buttons`}>
            <div className="new-message-buttons">
              {(!onlyIsideMessage || ship.iside) &&
                <>
                  {!advancedMessaging ?
                    <>
                      <div
                        className="conversation-button"
                        onClick={() => setSelectedMessage({ id: "C1" })}>
                        <img src={mayday_icon} alt={t("may_day")} />
                        <span>{t("may_day")}</span>
                      </div>
                      <div
                        className="conversation-button"
                        onClick={() => setSelectedMessage({ id: "C2" })}>
                        <img src={panpan_icon} alt={t("pan_pan")} />
                        <span>{t("pan_pan")}</span>
                      </div>
                      <div
                        className="conversation-button"
                        onClick={() => setSelectedMessage({ id: "C3" })}>
                        <img src={securite_icon} alt={t("securite")} />
                        <span>{t("securite")}</span>
                      </div>
                    </>
                    :
                    <div
                      className="conversation-button"
                      onClick={() => setSelectedMessage({ id: "NEW" })}>
                      <img src={new_message_icon} alt={t("new_message_label").toUpperCase()} />
                      <span>{t("new_message_label").toUpperCase()}</span>
                    </div>
                  }
                </>
              }
              <div
                className="conversation-button text-button"
                onClick={() => setSelectedMessage({ id: "CUSTOM", text: t("custom_text") })}>
                <img src={text_icon} alt={t("text").toUpperCase()} />
                <span>{t("text").toUpperCase()}</span>
              </div>
            </div>
          </div>
        </div>
      <Modal
      className={`${!useMobileLayout ? "desktop" : ""}`}
      show={selectedMessage != null}
      onHide={closeModal}
      centered>
      {!advancedMessaging ?
        <NewMessageModal
          closeModal={closeModal}
          selectedMessage={selectedMessage} />
        :
        <NewAdvancedMessageModal
          closeModal={closeModal}
          selectedMessage={selectedMessage} />
      }
    </Modal>
        </>
      }
      <div
        id={`ship-${ship.mmsi}`}
        className={
          `ship 
          ${ship.isBaseStation ? "base-station" : ""} 
          ${ship.selected ? "selected" : ""} 
          ${ship.outOfRange ? "disabled" : ""}`
        }
        onClick={handleShipClick}>
        {getShipContent()}
      </div>
    </>
  )
}

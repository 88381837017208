import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useWidth } from '../contexts/WidthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MiniMap from "./map/MiniMap";
import Footer from "./Footer";

import iside_icon from "../img/new/iside-logo-ships.png";
import anchor_icon from "../img/icons/anchor.png";
import anchor_icon_dark from "../img/icons/anchor_dark.png";
import { useTheme } from "../contexts/ThemeProvider";

function BoatInfo({ship, onClose}) {
  const { t } = useTranslation();
  const { useMobileLayout } = useWidth();
  const { isDarkTheme } = useTheme();
  
  function ConvertDDToDMS(D, lng) {
    return {
      dir: D < 0 ? (lng ? t('W') : "S") : lng ? "E" : "N",
      deg: 0 | (D < 0 ? (D = -D) : D),
      min: 0 | (((D += 1e-9) % 1) * 60),
      sec: Math.round((0 | (((D * 60) % 1) * 6000)) / 100)
    };
  }

  function formatPosition (pos,isLat){
    if(isLat){
      let lat= ConvertDDToDMS(pos[0],false)
      return `${lat.deg}°${lat.min}'${lat.sec}"${lat.dir}`;
    }else{
      let lng = ConvertDDToDMS(pos[1],true)
      return `${lng.deg}°${lng.min}'${lng.sec}"${lng.dir}`;
    }
  }

  let cargoString =null;
  if(ship.cargoCode!=null&& ((ship.cargoCode>=20&&ship.cargoCode<=99)||ship.cargoCode===0)){
     cargoString="cargo_"+ship.cargoCode
  }

  let navStatusCode =null;
  if(ship.navStatusCode!=null&& ((ship.navStatusCode>=0&&ship.navStatusCode<=8)||(ship.navStatusCode>=14&&ship.navStatusCode<=15))){
    navStatusCode="nav_"+ship.navStatusCode
  }

  let shipRateOfTurn=null;
  if(ship.rateOfTurn!=null){
    if(ship.rateOfTurn===0){
      shipRateOfTurn=0+" °/min";
    }
    if(ship.rateOfTurn===128||ship.rateOfTurn===-128){
      shipRateOfTurn=t('unavailable')
    }
    else if(ship.rateOfTurn>0){
      shipRateOfTurn=(Math.pow((ship.rateOfTurn/4.733),2)).toFixed(0)+" °/min";
    }else{
      shipRateOfTurn=-1*(Math.pow((ship.rateOfTurn/4.733),2)).toFixed(0)+" °/min";
    }
  }

  return (
    <Container className={`right-sidebar h-100 d-flex flex-column justify-content-between ${useMobileLayout ? "mobile" : "desktop"}`}>
      <div className="panel-header">
        {(useMobileLayout && (ship.name != null || ship.mmsi != null)) &&
          <div className="ship-info">
            <div className="ship-icon">
              {ship.isBaseStation && <img src={isDarkTheme ? anchor_icon_dark : anchor_icon} alt=""></img>}
              {(ship.iside && !ship.isBaseStation) && <img src={iside_icon} alt=""></img>}
            </div>
            <div className="ship-name">
              {ship.name != null ? ship.name : ship.mmsi}
            </div>
          </div>
        }
        <span className="ms-auto close-button">
          <FontAwesomeIcon
            icon="times"
            onClick={() => onClose()} />
        </span>
      </div>
      <div className="content overflow-auto">
        {ship != null && ship.latitude != null && ship.longitude != null ? <MiniMap ship={ship}/> : <></>}
        <div
          className={"overflow-auto boat-info-content"} style={{ textAlign: 'left' }}>
          {(!useMobileLayout && (ship.name != null || ship.mmsi != null)) && <div className="w-100 text-center ship-name">{ship.name != null ? ship.name : ship.mmsi}</div>}
              {ship.mmsi!=null?<p>{t('mmsi')}<span className="info-field">{ship.mmsi}</span></p>:null}
              {ship.name!=null&&ship.name!==""?<p>{t('name')}<span className="info-field">{ship.name}</span></p>:null}
              {ship.callsign!=null&&ship.callsign!==""?<p>{t('callsign')}<span className="info-field">{ship.callsign}</span></p>:null}
              {ship.destination!=null&&ship.destination!==""?<p>{t('destination')}<span className="info-field">{ship.destination}</span></p>:null}
              {cargoString!=null?<p>{t('cargo')}<span className="info-field">{t(cargoString)}</span></p>:null}
              {ship.latitude!=null?<p>{t('latitude')}<span className="info-field">{formatPosition([ship.latitude,ship.longitude],true)}</span></p>:null}
              {ship.longitude!=null?<p>{t('longitude')}<span className="info-field">{formatPosition([ship.latitude,ship.longitude],false)}</span></p>:null}
              {shipRateOfTurn!=null?<p>{t('rateOfTurn')}<span className="info-field">{shipRateOfTurn}</span></p>:null}
              {ship.speedOverGround!=null?<p>{t('speedOverGround')}<span className="info-field">{ship.speedOverGround===102.3?t('unavailable'):ship.speedOverGround+t('knots')}</span></p>:null}
              {ship.courseOverGround!=null?<p>{t('courseOverGround')}<span className="info-field">{ship.courseOverGround===360?t('unavailable'):ship.courseOverGround+" °"}</span></p>:null}
              {ship.heading!=null?<p>{t('heading')}<span className="info-field">{ship.heading===511?t('unavailable'):ship.heading+" °"}</span></p>:null}
              {navStatusCode!=null? <p>{t('navStatus')}<span className="info-field">{t(navStatusCode)}</span></p>:null}
              {ship.distance!=null?<p>{t('boat_distance')}<span className="info-field">{ship.distance.toFixed(2)} KM</span></p>:null}
        </div>
      </div>
      {useMobileLayout && <Footer />}
    </Container>
  );
}

export default BoatInfo;

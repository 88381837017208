import React from 'react'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

export default function ToggleSwitch({name, falseLabel, trueLabel, defaultValue = false, onChange}) {
  return (
    <ToggleButtonGroup
      className='toggle-group'
      type="radio"
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}>
      <ToggleButton
        className='toggle-button'
        id={`${name}-1`}
        value={false}>
        {falseLabel}
      </ToggleButton>
      <ToggleButton
        className='toggle-button'
        id={`${name}-2`}
        value={true}>
        {trueLabel}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

import React, { useState } from 'react'
import { Modal } from "react-bootstrap"
import { useFrasario } from "../contexts/FrasarioProvider";
import MessageCard from './MessageCard';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../contexts/ThemeProvider';

import back_icon from "../img/icons/left.png";
import back_icon_dark from "../img/icons/left_white.png";
import text_icon from "../img/icons/text_blu.png";
import text_icon_dark from "../img/icons/text_yellow.png";
import reply_icon from "../img/icons/reply.png";
import reply_icon_dark from "../img/icons/reply_white.png";

export default function NewMessageModal({ closeModal, selectedMessage }) {
  const isNewMessage =
    selectedMessage == null ||
    selectedMessage.id == null ||
    selectedMessage.id === "NEW";
  
  const { frasario, parseMessage } = useFrasario();
  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();
  const [newMessageTitle] = useState(t('new_message_label'));
  const [stack, setStack] = useState(() => {
    const parsedMessage = isNewMessage ? selectedMessage : parseMessage(selectedMessage);
    if (isNewMessage) {
      return [{
        title: newMessageTitle,
        children: frasario.NEW_MESSAGE_TREE
      }];
    }
    if (selectedMessage.id !== "CUSTOM") {
      if (selectedMessage.id === "C1") {
        return [{
          title: parsedMessage.text,
          icon: parsedMessage.icon,
          iconColor: parsedMessage.iconColor,
          children:
            frasario.MAY_DAY.children != null ? frasario.MAY_DAY.children : [{
              id: "CUSTOM"
            }]
        }];
      } else if (selectedMessage.id === "C2") {
        return [{
          title: parsedMessage.text,
          icon: parsedMessage.icon,
          iconColor: parsedMessage.iconColor,
          children:
            frasario.PAN_PAN.children != null ? frasario.PAN_PAN.children : [{
              id: "CUSTOM"
            }]
        }];
      } else if (selectedMessage.id === "C3") {
        return [{
          title: parsedMessage.text,
          icon: parsedMessage.icon,
          iconColor: parsedMessage.iconColor,
          children:
            frasario.SECURITE.children != null ? frasario.SECURITE.children : [{
              id: "CUSTOM"
            }]
        }];
      } else {
        return [{
          title: parsedMessage.text,
          icon: parsedMessage.icon,
          children:
            parsedMessage.answers != null ? [...parsedMessage.answers, {
              id: "CUSTOM",
              noIcon: parsedMessage.answers.length > 0
            }] : [{
              id: "CUSTOM"
            }]
        }];
      }
    } else {
      return [{
        title: selectedMessage.text,
        isCustom: true,
        children: [{
          id: "CUSTOM",
          edit: true
        }]
      }];
    }
  });

  function handleMessageClick(message) {
    if (message == null) {
      closeModal(null);
      return;
    }
    if (message.id === "CUSTOM") {
      setStack([...stack, { title: message.text, isCustom: true, children: [{ ...message, edit: true }] }])
    } else if (message.children != null) {
      setStack((previousStack) => {
        return [...previousStack, { title: message.text, icon: message.icon, iconColor: message.iconColor, children: message.children }];
      })
    }
  }

  function handleBackClick() {
    if (stack.length > 1) {
      let newStack = [...stack];
      newStack.pop();
      setStack(newStack);
    } else {
      closeModal(null);
    }
  }

  return (
    <>
      <Modal.Header closeButton closeVariant={isDarkTheme ? "white" : null}>
        {stack != null && stack.length > 1 &&
          <div className='button' onClick={handleBackClick}>
            <img src={isDarkTheme ? back_icon_dark : back_icon} alt={t("back")} />
          </div>
        }
        {stack != null &&
          <div className="title">
            {selectedMessage != null && selectedMessage.isReply ?
              <div className="title-icon">
                <img src={isDarkTheme ? reply_icon_dark : reply_icon} alt="" />
              </div>
              :
              stack[stack.length - 1].icon != null ?
                <div className="title-icon">
                  <img src={`./images/icons/${stack[stack.length - 1].icon}.png`} alt="" />
                </div>
                :
                stack[stack.length - 1].isCustom ?
                  <div className="title-icon small">
                    <img src={isDarkTheme ? text_icon_dark : text_icon} alt="" />
                  </div>
                  :
                  null
            }
            
            <div className="title-text">{stack[stack.length - 1].title}</div>
          </div>
        }
      </Modal.Header>
      <Modal.Body>
        <div className='body'>
          {
            stack != null &&
            stack[stack.length - 1].children != null &&
            stack[stack.length - 1].children.map(
              (message, index) => {
                return <MessageCard
                  key={index}
                  onClick={handleMessageClick}
                  onSubmit={closeModal}
                  message={message}
                />;
              }
            )
          }
        </div>
      </Modal.Body>
    </>
  )
}

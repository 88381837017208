import React, { useContext, useState } from 'react'

const DEFAULT_BOAT_INFO = {
  BOAT_NAME: "NAME",
  MMSI: "00000",
  LANGUAGE: "IT"
}

const BoatInfoContext = React.createContext();

export function useBoatInfo() {
  return useContext(BoatInfoContext);
}

export function BoatInfoProvider({ children }) {
  const [boatInfo, setBoatInfo] = useState(DEFAULT_BOAT_INFO);

  const value = {
    boatInfo,
    updateBoatInfo: setBoatInfo
  }

  return (
    <BoatInfoContext.Provider value={value}>
      {children}
    </BoatInfoContext.Provider>
  )
}

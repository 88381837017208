import React, { useContext, useState } from 'react'
import {Modal } from "react-bootstrap";
import MapModal from "../components/map/MapModal";
import { useWidth } from '../contexts/WidthProvider';

const MAP_SERVER_PORT = 8081;
const MAP_SERVER_URL = `${window.location.protocol}//${window.location.hostname}:${MAP_SERVER_PORT}`;
const USE_OFFLINE_MAP = true;
const DEFAULT_POSITION = [43.443948, 9.336278];
const DEFAULT_MODAL_OPTIONS = {
  isInteractive: false,
  initialPosition: null,
  initialBounds: null,
  initialSelection: null
}

const MapContext = React.createContext();

export function useMap() {
  return useContext(MapContext);
}

export function MapProvider({ children }) {
  const [showPositionModal,setShowPositionModal]= useState(false);
  const [positionModalCallback, setPositionModalCallback] = useState(null);
  const [modalOptions, setModalOptions] = useState({ ...DEFAULT_MODAL_OPTIONS });
  const { useMobileLayout } = useWidth();
  
  function OpenMapModal(options = {}) {
    setPositionModalCallback(() => options.callback);
    setModalOptions({
      isInteractive: options.interactive,
      initialPosition: options.initialPosition,
      initialBounds: options.initialBounds,
      initialSelection: options.initialSelection
    })
    setShowPositionModal(true);
  }
  
  function CloseMapModal(value){
    if (positionModalCallback != null){
      positionModalCallback(value);
      setPositionModalCallback(null);
    }
    setModalOptions({ ...DEFAULT_MODAL_OPTIONS });
    setShowPositionModal(false);
  }

  const value = {
    OpenMapModal,
    USE_OFFLINE_MAP,
    MAP_SERVER_URL,
    DEFAULT_POSITION
  }

  return (
    <MapContext.Provider value={value}>
      {children}
        <Modal
          className={!useMobileLayout? "desktop": ""}
          show={showPositionModal}
          backdrop="static"
          onHide={CloseMapModal}
          centered>        
          <MapModal
            closeModal={CloseMapModal}
            options={modalOptions != null ? modalOptions : {}}
          />
        </Modal>
    </MapContext.Provider>
  )
}

import React, { useContext, useState, useEffect } from 'react'
import useLocalStorage from '../hooks/useLocalStorage';
import { useConversations } from './ConversationsProvider';
import { useSocket } from './SocketProvider';
import { useSpeech } from '../contexts/SpeechProvider';
import { useFrasario } from "../contexts/FrasarioProvider";
import { useTranslation } from 'react-i18next';

const ShipsContext = React.createContext();

export function useShips() {
  return useContext(ShipsContext);
}

export function ShipsProvider({ children }) {
  const { socket } = useSocket();
  const [ships, setShips] = useLocalStorage("ships", []);
  const [isLoading, setIsLoading] = useState(true);
  const [shipFilter, setShipFilter] = useState("");
  const [selectedShipMmsi, setSelectedShipMmsi] = useState("");
  const { conversations } = useConversations();
  const { parseMessage } = useFrasario();
  const { t } = useTranslation();
  const { speak } = useSpeech();


  useEffect(() => {
    if (socket == null) return;

    socket.on("shipList", (shipList) => {
      //console.log("Received shipList", shipList);
      setShips([...shipList]);
      if (isLoading === true) {
        setIsLoading(false);
      }
    });

    socket.on("messageReceived", (message) => {
      if (message != null) {
        try {
          // console.log(`Received message: `, message);
          if (selectedShipMmsi != null) {
            if (conversations[selectedShipMmsi] != null
              && conversations[selectedShipMmsi].unreadMessages != null
              && conversations[selectedShipMmsi].unreadMessages !== 0) {
              socket.emit("shipSelected", selectedShipMmsi);
            }
          }
          let senderMMSI = message.sender != null ? message.sender.MMSI : null;
          if (senderMMSI == null) return;
          let messageAfterParse = parseMessage(message,false,false,true)
          if (messageAfterParse == null) return;
          var found_ship = ships.find(ship => ship.mmsi === senderMMSI);
          let messageToRead = t('new_message_received');
          if(senderMMSI===selectedShipMmsi){
            messageToRead= messageAfterParse.text;
          } else {
            if (found_ship != null && found_ship.name != null) {
              messageToRead += found_ship.name  +"... ";
            } else {
              messageToRead += senderMMSI + "... ";
            }
            messageToRead += messageAfterParse.text;
          }
          //setParsedMessage(messageToRead);
          speak(messageToRead, t('language'));
        } catch (e) {
          console.log(`Exception while trying to read received message`, e);
        }
      }
    });

    return () => socket.off("shipList").off("messageReceived");
  }, [socket, setShips, isLoading, setIsLoading, selectedShipMmsi, conversations,parseMessage,ships,speak,t]);

  const filteredShips = ships.map((ship) => {
    const selected = ship.mmsi === selectedShipMmsi;
    return { ...ship, selected };
  }).filter((ship) => {
    if (!ship.isDummy && (ship.latitude == null || ship.longitude == null)) return false; //Do not display ships without position
    if (shipFilter == null || shipFilter === "") return true;
    const shipName = ship.name != null ? ship.name.toLowerCase() :  conversations[ship.mmsi] != null && conversations[ship.mmsi].shipName != null ? conversations[ship.mmsi].shipName.toLowerCase() : "";
    const shipMmsi = ship.mmsi != null ? ship.mmsi.toLowerCase() : "";
    return shipName.includes(shipFilter.toLowerCase())
        || shipMmsi.includes(shipFilter.toLowerCase());
  }).sort((s1, s2) => {
    if ((conversations[s1.mmsi] == null || conversations[s1.mmsi].lastMessageTimestamp == null) && (conversations[s2.mmsi] == null || conversations[s2.mmsi].lastMessageTimestamp == null)) return s1.distance - s2.distance;
    if (conversations[s1.mmsi] == null || conversations[s1.mmsi].lastMessageTimestamp == null) return 1;
    if (conversations[s2.mmsi] == null || conversations[s2.mmsi].lastMessageTimestamp == null) return -1;
    return conversations[s2.mmsi].lastMessageTimestamp - conversations[s1.mmsi].lastMessageTimestamp;
  });

  const shipsWithMessages = Object.keys(conversations).filter(mmsi=>conversations[mmsi].messages!=null && conversations[mmsi].messages.length >0).map(mmsi=>{
    const selected = mmsi===selectedShipMmsi;
    let ship= ships.find(s=> s.mmsi===mmsi);
    if(ship!=null){
      return{
        ...ship,
        selected,
        outOfRange:false
      }
    }else{
      return{
        mmsi,
        name:conversations[mmsi].shipName,
        isBaseStation:conversations[mmsi].isBaseStation,
        selected,
        outOfRange:true
      }
    }
  }).filter((ship) => {
    if (shipFilter == null || shipFilter === "") return true;
    const shipName = ship.name != null ? ship.name.toLowerCase() :  conversations[ship.mmsi] != null && conversations[ship.mmsi].shipName != null ? conversations[ship.mmsi].shipName.toLowerCase() : "";
    const shipMmsi = ship.mmsi != null ? ship.mmsi.toLowerCase() : "";
    return shipName.includes(shipFilter.toLowerCase())
        || shipMmsi.includes(shipFilter.toLowerCase());
  }).sort((s1, s2) => {
    if ( conversations[s1.mmsi].lastMessageTimestamp == null && conversations[s2.mmsi].lastMessageTimestamp == null) return 0;
    if ( conversations[s1.mmsi].lastMessageTimestamp == null) return 1;
    if ( conversations[s2.mmsi].lastMessageTimestamp == null) return -1;
    return conversations[s2.mmsi].lastMessageTimestamp - conversations[s1.mmsi].lastMessageTimestamp;
  });

  function selectShipMmsi(mmsi) {
    setSelectedShipMmsi(mmsi);
    if (conversations[mmsi] != null
      && conversations[mmsi].unreadMessages != null
      && conversations[mmsi].unreadMessages !== 0) {
      socket.emit("shipSelected", mmsi);
    }
  }

  const value = {
    ships: filteredShips,
    unfilteredShips: ships,
    isLoading,
    shipFilter,
    filterShips: setShipFilter,
    selectShipMmsi,
    selectedShip: filteredShips.find((ship) => ship.mmsi === selectedShipMmsi) || shipsWithMessages.find(ship=> ship.mmsi===selectedShipMmsi),
    shipsWithMessages
  }

  return (
    <ShipsContext.Provider value={value}>
      {children}
    </ShipsContext.Provider>
  )
}

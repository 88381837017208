import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SearchBar({ placeholder, searchQuery, setSearchQuery }) {
  return (
    <Form className="search-bar">
      <InputGroup>
        <InputGroup.Text className="search-icon">
          <FontAwesomeIcon
            size="sm"
            icon="search"
          />
        </InputGroup.Text>
        <Form.Control
          className="search-text"
          size="sm"
          type="text"
          placeholder={placeholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <InputGroup.Text
          className="search-cancel"
          onClick={() => setSearchQuery("")}>
          <FontAwesomeIcon
            size="sm"
            icon="times"
          />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  )
}

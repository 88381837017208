import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { SocketProvider } from "./contexts/SocketProvider";
import { ShipsProvider } from './contexts/ShipsProvider';
import { ConversationsProvider } from './contexts/ConversationsProvider';
import { BoatInfoProvider } from './contexts/BoatInfoProvider';
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/index.css';
import './css/Colors.css';
import { FrasarioProvider } from './contexts/FrasarioProvider';
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { LocationProvider } from './contexts/LocationProvider';
import './i18n/config';
import { WidthProvider } from './contexts/WidthProvider';
import { SpeechProvider } from './contexts/SpeechProvider';
import { MapProvider } from './contexts/MapProvider';
import { SettingsProvider } from './contexts/SettingsProvider';
import { InfoProvider } from './contexts/InfoProvider';
import { ThemeProvider } from './contexts/ThemeProvider';

library.add(fas)

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider>
            <WidthProvider>
                <InfoProvider>
                    <SettingsProvider>
                        <BoatInfoProvider>
                            <FrasarioProvider>
                                <SocketProvider>
                                    <SpeechProvider>
                                        <ConversationsProvider>
                                            <ShipsProvider>
                                                <LocationProvider>
                                                    <MapProvider>
                                                        <App />
                                                    </MapProvider>
                                                </LocationProvider>        
                                            </ShipsProvider>
                                        </ConversationsProvider>
                                    </SpeechProvider>
                                </SocketProvider>
                            </FrasarioProvider>
                        </BoatInfoProvider>
                    </SettingsProvider>
                </InfoProvider>
            </WidthProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

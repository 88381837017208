import React, { useContext, useEffect, useState } from 'react'
import io from "socket.io-client";
import { useBoatInfo } from './BoatInfoProvider';
import { useFrasario } from './FrasarioProvider';
import { useSettings } from './SettingsProvider';

const SOCKET_PORT = 33;
const SERVER = `${window.location.protocol}//${window.location.hostname}:${SOCKET_PORT}`;

const SocketContext = React.createContext();


export function useSocket() {
  return useContext(SocketContext);
}
export function SocketProvider({ children }) {
  const [socket, setSocket] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const { updateBoatInfo } = useBoatInfo();
  const { setFrasario } = useFrasario();
  const { language, setLanguage, setAisStatus } = useSettings();

  useEffect(() => { 
    const newSocket = io(SERVER);
    newSocket.on("connection", (info) => {
      setAisStatus(info.aisStatus);
      const newBoatInfo = {
        MMSI: info.MMSI,
        BOAT_NAME: info.BOAT_NAME,
        LANGUAGE: info.LANGUAGE
      }
      console.log(`Successfully connected with the back-end!\nCurrent boat informations: `, newBoatInfo);
      console.log("AIS status: ", info.aisStatus === true || info.aisStatus === "true" ? "ENABLED" : "DISABLED");
      if (newBoatInfo.LANGUAGE != null && language == null) {
        setLanguage(newBoatInfo.LANGUAGE.toLowerCase());
      }
      updateBoatInfo((prevBoatInfo) => {
        return { ...prevBoatInfo, ...newBoatInfo };
      });
      setFrasario(info.FRASARIO);
      setIsConnected(true);
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, [updateBoatInfo, setFrasario, language, setLanguage, setAisStatus]);

  function resetApplicationState() {
    socket.emit("reset");
  }

  const value = {
    socket,
    isConnected,
    resetApplicationState
  }

  return (
    <SocketContext.Provider value={value}>
      {children}
    </SocketContext.Provider>
  )
}

import React,{useState,useEffect}from 'react'
import { Button, Modal,Stack, Form, Accordion} from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useSettings } from '../contexts/SettingsProvider';
import { useSocket } from "../contexts/SocketProvider";
import  UserConfirmModal  from "./UserConfirmModal";
import { useInfo } from '../contexts/InfoProvider';
import { useWidth } from '../contexts/WidthProvider';
import ToggleSwitch from './ToggleSwitch';
import { useTheme } from '../contexts/ThemeProvider';

export default function SettingsModal({show, onHide}) {
  const { t, i18n } = useTranslation();
  const { isDarkTheme } = useTheme();
  const { useMobileLayout } = useWidth();
  const { speechEnabled,
    setSpeechEnabled,
    language,
    setLanguage,
    onlyIsideMessage,
    setOnlyIsideMessage,
    advancedMessaging,
    setAdvancedMessaging,
    useInternet,
    setUseInternet,
    aistype,
    setAistype,
    supportedAistypes,
    aisStatus/*,
  resetSettings*/ } = useSettings();
  const [ lang, setLang ] = useState(language || i18n.language);
  const [ checked, setChecked ]= useState(speechEnabled);
  const [ checkedIside, setCheckedIside ]= useState(onlyIsideMessage);
  const [ checkedAdvancedMsg, setCheckedAdvancedMsg ]= useState(advancedMessaging);
  const [ checkedUseInternet, setCheckedUseInternet ] = useState(useInternet);
  const [ selectedAistype, setSelectedAistype ] = useState(aistype);
  const [ openResetModal, setOpenResetModal ]= useState(false);
  const { resetApplicationState } = useSocket();
  const { OpenInfoModal } = useInfo();

  useEffect(()=>{
    setLang(language || i18n.language);
    setChecked(speechEnabled);
    setCheckedIside(onlyIsideMessage);
    setCheckedAdvancedMsg(advancedMessaging); 
    setCheckedUseInternet(useInternet);
    setSelectedAistype(aistype);
  },[speechEnabled,language,onlyIsideMessage,advancedMessaging,useInternet,i18n.language,aistype])
  
  function confirmReset() {
    resetApplicationState();
    //resetSettings();
    setOpenResetModal(false);
    onCancel();
  }

  function onCancel() {
    setLang(language || i18n.language);
    setChecked(speechEnabled);
    setCheckedIside(onlyIsideMessage);
    setCheckedAdvancedMsg(advancedMessaging); 
    setCheckedUseInternet(useInternet);
    setSelectedAistype(aistype);
    onHide();
  }

  function closeResetModal() {
    setOpenResetModal(false);
  }

  function applySettings() {
    if(lang!==language){
      setLanguage(lang);
    }
    if(checked!==speechEnabled){
      setSpeechEnabled(checked); 
    }
    if(checkedIside!==onlyIsideMessage){
      setOnlyIsideMessage(checkedIside); 
    }
    if(checkedAdvancedMsg!==advancedMessaging){
      setAdvancedMessaging(checkedAdvancedMsg); 
    }
    if(checkedUseInternet!==useInternet){
      setUseInternet(checkedUseInternet); 
    }
    if(!checkedUseInternet && selectedAistype!==aistype){
      setAistype(selectedAistype); 
    }
    onHide();
  }
  
  return (
    <>
    <Modal
      className={`${!useMobileLayout ? "desktop" : ""}`}
      show={show}
      onHide={onCancel}
      centered>
      <Modal.Header closeButton closeVariant={isDarkTheme ? "white" : null}>
        <div className='title'>
          <div className="title-text">
            {t('settings')}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
      <Stack
          direction='vertical'
          gap="2"
          className='body settings'
        >
        <div>
          <Form>
          <Form.Label><h5>{t('select_language')}</h5></Form.Label>
            <Form.Select
              onChange={(e) => setLang(e.target.value)}
              value={lang}>
              {i18n.supportedLanguages.map((l,index)=> <option key = {index} value = {l}>{t(l)}</option>)}
            </Form.Select>
          </Form>
          <hr/>
        </div>
        <div>
          <h5>{t('advanced_messaging')}</h5>
          <ToggleSwitch
            name="advancedMsgToggle"
            falseLabel={t('inactive')}
            trueLabel={t('active')}
            defaultValue={checkedAdvancedMsg}
            onChange={(value) => {
              setCheckedAdvancedMsg(value)
            }}
          />
          <hr/>
        </div>
        <div>
          <h5>{t('select_speech')}</h5>
          <ToggleSwitch
            name="speechToggle"
            falseLabel={t('inactive')}
            trueLabel={t('active')}
            defaultValue={checked}
            onChange={(value) => {
              setChecked(value)
            }}
          />
          <hr/>
        </div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header><h5>{t('developer_option')}</h5></Accordion.Header>
            <Accordion.Body>
              <div>
                <h5>{t('iside_only_messages')}</h5>
                <ToggleSwitch
                  name="isideToggle"
                  falseLabel={t('inactive')}
                  trueLabel={t('active')}
                  defaultValue={checkedIside}
                  onChange={(value) => {
                    setCheckedIside(value)
                  }}
                />
                <hr/>
              </div>
              <div style={{ display: aisStatus ? "none" : "block" }}>
                <h5>{t('ais_not_enabled')}</h5>
                <hr />
              </div>
              <div style={{display: aisStatus ? "block" : "none"}}>
                <div>
                  <h5>{t('use_internet')}</h5>
                  <ToggleSwitch
                    name="internetToggle"
                    falseLabel={t('inactive')}
                    trueLabel={t('active')}
                    defaultValue={checkedUseInternet}
                    onChange={(value) => {
                      setCheckedUseInternet(value)
                    }}
                  />
                  <hr />
                </div>
                <div style={{display: checkedUseInternet?"none":"block"}}>
                  <Form>
                  <Form.Label><h5>{t('select_aistype')}</h5></Form.Label>
                    <Form.Select
                      onChange={(e) => setSelectedAistype(e.target.value)}
                      value={selectedAistype}>
                      {supportedAistypes.map((t,index)=> <option key = {index} value = {t}>{t}</option>)}
                    </Form.Select>
                  </Form>
                  <hr />
                </div>
              </div>
              <div>
                  <h5>{t('reset_application_text')}</h5>
                  <div className="modal-button danger" onClick={() => setOpenResetModal(true)}>
                    {t('reset_application')}
                  </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className='m-auto'>
          <Button
              variant='link'
              size='sm'
              onClick={() => OpenInfoModal()}>
              {t('about_iside_project')}
          </Button>
        </div>
      </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Stack
        direction='horizontal'
        gap="2"
        className=''>
        <div className="modal-button secondary" onClick={onCancel}>
          {t('cancel')}
        </div>
        <div className="modal-button" onClick={applySettings}>
          {t('confirm_text')}
        </div>
        </Stack>
      </Modal.Footer>
      </Modal>
      <Modal
        className={`${!useMobileLayout ? "desktop" : ""}`}
        show={openResetModal}
        onHide={closeResetModal}
        centered>
        <UserConfirmModal
          onConfirm={confirmReset}
          onCancel={closeResetModal}
          title={t('reset_message')}
          text={t('reset_question')}
          confirmButtonText={t('ok')}
          cancelButtonText={t('cancel')}
        />
      </Modal>
    </>
  )
}

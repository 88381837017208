import React, { useContext } from 'react'
import useLocalStorage from '../hooks/useLocalStorage';
import { useSettings } from '../contexts/SettingsProvider';
import { useBoatInfo } from './BoatInfoProvider';
import { useTranslation } from 'react-i18next';
const FrasarioContext = React.createContext();

export function useFrasario() {
  return useContext(FrasarioContext);
}

export function FrasarioProvider({ children }) {
  const { boatInfo } = useBoatInfo();
  const { language } = useSettings();
  const { t, i18n } = useTranslation();
  const [frasario, setFrasario] = useLocalStorage(
    "frasario", {
      MESSAGES: {},
      NEW_MESSAGE_TREE: []
    }
  );

  function parseMessage(message, ignoreParams = false, maskParams=false,readPosition=false) {
    // console.log("Parsing ", message);
    if (message == null || message.id == null) {
      console.log("Unable to parse a message with no id");
      return null;
    }
    let parsedMessage = { ...message };

    if (parsedMessage.id === "CUSTOM"
      && (parsedMessage.received
        || parsedMessage.text != null)) {
      return parsedMessage;
    }

    const frasarioMessage = frasario.MESSAGES[message.id];
    if (frasarioMessage == null) {
      console.log("No message found with given id: ", message.id);
      return null;
    }
    let lang= language||i18n.language
    let text = frasarioMessage.text[lang!=null?lang.toUpperCase():"EN"];
    if (text == null) {
      text = frasarioMessage.text["EN"];
    }
    parsedMessage.text = text != null ? text : "";

    if (frasarioMessage.params != null && !ignoreParams) {
      frasarioMessage.params.forEach(param => {
        switch (param.type.toUpperCase()) {
          case "BOAT_INFO":
            if ((parsedMessage.received
              && (parsedMessage.params == null
                || parsedMessage.params[param.name] == null))
              || boatInfo[param.name] == null) {
              console.log(`Invalid param ${param.name}`);
              break;
            }
            const boat_info = parsedMessage.received ? parsedMessage.params[param.name] : boatInfo[param.name];
            parsedMessage.text = parsedMessage.text.replace(new RegExp(`{{${param.name}}}`, 'g'), boat_info);
            break;
          case "SELECT":
            const paramValue = parsedMessage.params != null ? parsedMessage.params[param.name] : null;
            if (paramValue == null
              || typeof paramValue !== 'number'
              || paramValue < 0
              || paramValue >= param.options.length) {
              console.log(`Invalid param ${param.name} value: `, paramValue);
              break;
            }
            let optionText = param.options[paramValue][lang!=null?lang.toUpperCase():"EN"];
            if (optionText == null) {
              optionText = param.options[paramValue]["EN"];
            }
            parsedMessage.text = parsedMessage.text.replace(new RegExp(`{{${param.name}}}`, 'g'), optionText);
            break;
          case "TEXT":
          case "SPEED":
          case "NUMBER":
            if (parsedMessage.params == null
              || parsedMessage.params[param.name] == null) {
              console.log(`Invalid param ${param.name}`);
            } else {
              parsedMessage.text = parsedMessage.text.replace(new RegExp(`{{${param.name}}}`, 'g'), parsedMessage.params[param.name]);
            }
            break;
          case "POSITION":
            if (parsedMessage.params == null
              || parsedMessage.params[param.name] == null) {
              console.log(`Invalid param ${param.name}`);
            } else {
              if(readPosition){
                parsedMessage.text = parsedMessage.text.replace(new RegExp(`{{${param.name}}}`, 'g'), parsedMessage.params[param.name].replace(/°/g," "+t('degrees')+" ... ").replace(/'/g, " "+t('minutes')+" ... ").replace(/"/g," "+ t('seconds')+" ... ").replace(/N/g, " "+t('north')+" ... ").replace(/S/g," "+t('south')+" ... ").replace(/E/g," "+t('east')+" ... ").replace(/W/g," "+t('west')+" ... "));
              }else{
                parsedMessage.text = parsedMessage.text.replace(new RegExp(`{{${param.name}}}`, 'g'), parsedMessage.params[param.name]);
              }
            }
          break;
          default:
            console.log("Unsupported parameter type: ", param.type);
            break;
        }
      });
      if (!parsedMessage.received) {
        parsedMessage.params = [...frasarioMessage.params];
      }
    }
    else if(frasarioMessage.params!=null&& maskParams){
      frasarioMessage.params.forEach(param => {
        if(param.type!=="BOAT_INFO"){
          parsedMessage.text=parsedMessage.text.replace(new RegExp(`{{${param.name}}}`, 'g'), '...')
        }else{
          if ((parsedMessage.received
            && (parsedMessage.params == null
              || parsedMessage.params[param.name] == null))
            || boatInfo[param.name] == null) {
            console.log(`Invalid param ${param.name}`);
          }else if(message.hasParam){
            const boat_info = parsedMessage.received ? parsedMessage.params[param.name] : boatInfo[param.name];
            parsedMessage.text = parsedMessage.text.replace(new RegExp(`{{${param.name}}}`, 'g'), boat_info);
          }
        }
      });

    }
    if (frasarioMessage.answers != null) {
      parsedMessage.answers = frasarioMessage.answers.map(id => { return { id: id } });
    }

    if (frasarioMessage.icon != null) {
      parsedMessage.icon = frasarioMessage.icon;
    }
    if (frasarioMessage.iconColor != null) {
      parsedMessage.iconColor = frasarioMessage.iconColor;
    }
    return parsedMessage;
  }

  /*function formatOutgoingMessage(message) {
    console.log("Formatting ", message);
    if (message == null || message.id == null) {
      console.log("Unable to format a message with no id");
      return null;
    }

    if (message.id === "CUSTOM") {
      return { id: message.id, text: message.text };
    }

    const frasarioMessage = frasario.MESSAGES[message.id];
    if (frasarioMessage == null) {
      console.log("No message found with given id: ", message.id);
      return null;
    }

    let formattedMessage = { id: message.id };
  }*/

  const value = {
    frasario, 
    setFrasario,
    parseMessage
  }
  return (
    <FrasarioContext.Provider value={value}>
      {children}
    </FrasarioContext.Provider>
  )
}

import React from 'react'
import { Modal, Row, Col,Container} from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import logo_unica from "../img/1 logo UNICA bd.jpg"
import interreg_logo from "../img/interreg.jpg"
import siit_logo from "../img/2 siit-logo.png"
import cg_logo from "../img/8 guardia costiera.png"
import tir_logo from "../img/logo-porti-di-roma.png"
import sar_logo from "../img/4 AP Sardeigne MARCHIO VERTICALE.jpg"
import navigo_logo from "../img/5 Navigo_400x400.jpg"
import cci_logo from "../img/6 CCI_LOGO_H-B (2).png"
import var_logo from "../img/7 CCI Var - SansBaseline - bleuCMJN.png"
import { useWidth } from '../contexts/WidthProvider';
import { useTheme } from '../contexts/ThemeProvider';


export default function InfoModal({show, onHide}) {
  const { t } = useTranslation();
  const { useMobileLayout } = useWidth();
  const { isDarkTheme } = useTheme();
  
  return (
    <Modal
      className={!useMobileLayout?"desktop":""}
      show={show}
      onHide={onHide}
      centered>
        <Modal.Header closeButton closeVariant={isDarkTheme ? "white" : null}>
        <div className='title'>
          <div className="title-text">
            {t("info")}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="body">
          <h5 className='text-center'><img src={interreg_logo} style={{width:"100%"}} alt="ISIDE LOGO"></img></h5>
          <h3 className='text-center'>{t('info_app')}<a href ={t('iside_link')} target="_blank" rel="noreferrer"> {t('project_iside')}</a></h3>   
          <hr/>   
          <h3 className='text-center' style={{paddingBottom:"30px"}}>{t('partner')}</h3> 
          <Container>
            <Row>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'pb-3':'p-3'}`}><img src={logo_unica} alt="UNICA LOGO"/></Col>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'py-3':'p-3'}`}><img src={siit_logo}alt="SIIT LOGO"/></Col>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'py-3':'p-3'}`}><img src={cg_logo} alt="COAST GUARD LOGO"/></Col>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'py-3':'p-3'}`}><img src={tir_logo} alt=""/></Col>
            </Row>
            <Row>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'py-3':'p-3'}`}><img src={sar_logo} alt="SAR LOGO"/></Col>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'py-3':'p-3'}`}><img src={navigo_logo}alt="NAVIGO LOGO"/></Col>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'py-3':'p-3'}`}><img src={cci_logo} alt="CCI LOGO"/></Col>
              <Col xs={6} lg={true} className={`partner ${useMobileLayout?'py-3':'p-3'}`}><img src={var_logo} alt="VAR LOGO"/></Col>
            </Row>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  )
}

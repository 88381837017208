import React, { useContext, useState } from 'react'
import InfoModal from "../components/InfoModal";


const InfoContext = React.createContext();

export function useInfo() {
  return useContext(InfoContext);
}

export function InfoProvider({ children }) {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  
  function closeInfoModal() {
    setOpenInfoModal(false);
  }

  function OpenInfoModal() {
    setOpenInfoModal(true);
  }

  const value = {
    OpenInfoModal
  }

  return (
    <InfoContext.Provider value={value}>
      {children}
      <InfoModal
          show={openInfoModal}
          onHide={closeInfoModal}
        />
    </InfoContext.Provider>
  )
}

import React, { useContext, useState, useEffect } from 'react'
import useLocalStorage from '../hooks/useLocalStorage';
import { useSocket } from './SocketProvider';
import { useSettings } from '../contexts/SettingsProvider';


const ConversationsContext = React.createContext();

export function useConversations() {
  return useContext(ConversationsContext);
}

export function ConversationsProvider({ children }) {
  const { socket } = useSocket();
  const [conversations, setConversations] = useLocalStorage('conversations', {});
  const [isLoading, setIsLoading] = useState(true);
  const {useInternet, aistype} = useSettings();

  
  useEffect(() => {
    if (socket == null) return;

    socket.on("conversationList", (conversationList) => {
      setConversations(conversationList);
      setIsLoading(false);
    });

    return () => socket.off("conversationList");
  }, [socket, setConversations, setIsLoading]);


  function addMessageToConversation({ mmsi, message, received }) {
    setConversations((prevConversations) => {
      const newMessage = {
        ...message,
        received
      };
      if (prevConversations[mmsi] != null && prevConversations[mmsi].messages != null) {
        prevConversations[mmsi].messages.push(newMessage);
      } else if (prevConversations[mmsi] == null) {
        prevConversations[mmsi] = { messages: [newMessage] };
      } else {
        prevConversations[mmsi].messages = [newMessage];
      }
      return prevConversations;
    });
  }

  function sendMessage(recipientMmsi, message) {
    socket.emit("message", { recipientMmsi, message, useInternet, aistype});
    console.log("sending message: ",message);
    addMessageToConversation({ mmsi: recipientMmsi, message, received: false});
  }

  const value = {
    conversations,
    isLoading,
    sendMessage
  }

  return (
    <ConversationsContext.Provider value={value}>
      {children}
    </ConversationsContext.Provider>
    
  )
}

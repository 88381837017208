import ShipList from "../components/ShipList";
import SearchBar from "../components/SearchBar";
import { useShips } from "../contexts/ShipsProvider";
import { Container } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useWidth } from '../contexts/WidthProvider';
import ToggleSwitch from "../components/ToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Home() {
  const { t } = useTranslation();
  const { useMobileLayout } = useWidth();
  const { ships, isLoading: isLoadingShips, shipFilter, filterShips, selectShipMmsi, shipsWithMessages} = useShips();
  const [history, setHistory] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  return (
    <Container className={`Home ${useMobileLayout ? "mobile" : "desktop"}`}>
      {isLoadingShips ?
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div>{t('loading')}</div>
        </div>
        :
        <>
          <div className="home-header">
            <div className={`panel-header ${useMobileLayout && !showSearchBar ? "hidden"  : ""}`}>
              <div className="d-flex flex-column w-100 align-items-start">
                {!useMobileLayout && <span className="accent f-500">{t("ships")}</span>}
                <SearchBar placeholder={t('search_ships')} searchQuery={shipFilter} setSearchQuery={filterShips} />
              </div>
            </div>
            <div className="history-toggle-container">
              <div className="accent f-500 title">{t("ships")}</div>
              <div className="history-toggle">
                <ToggleSwitch
                  name="historyToggle"
                  falseLabel={t("available")}
                  trueLabel={t("history")}
                  onChange={(value) => {
                    selectShipMmsi(null);
                    setHistory(value);
                  }}
                />
              </div>
              <div className="text-accent f-500 search-button"
                onClick={() => {
                  if (showSearchBar) {
                    filterShips("");
                  }
                  setShowSearchBar(!showSearchBar);
                }}>
                <FontAwesomeIcon
                  icon="search"/>
              </div>
            </div>
          </div>
          <div className="ship-list-wrapper">
            <div className="content ship-list overflow-auto">
              <ShipList ships={history?shipsWithMessages:ships}/>
            </div>
          </div>
        </>
      }
    </Container>
  );
}

export default Home;

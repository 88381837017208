import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactDOMServer from 'react-dom/server';
import { useLocation } from '../../contexts/LocationProvider';
import { useBoatInfo } from '../../contexts/BoatInfoProvider';
import { useTranslation } from 'react-i18next';
import { Marker, Popup } from 'react-leaflet'
import { DivIcon } from 'leaflet'

export default function MyPositionMarker({defaultPosition, onClick, interactive=true}) {
  const { coords } = useLocation();
  const { boatInfo } = useBoatInfo();
  const { t } = useTranslation();
  const renderedPosition = coords != null ? coords : defaultPosition;

  const myPositionIconHTML = ReactDOMServer.renderToString(<FontAwesomeIcon icon='map-marker-alt' color='#388E3C' style={{width: "24px", height:"40px"}} />)
  const myPositionIcon = new DivIcon({
    html: myPositionIconHTML,
    iconSize: [24, 40], 
    iconAnchor: [12, 40],
    popupAnchor: [0, -20]
  });
  
  return (
    <>
      <Marker
        position={renderedPosition}
        interactive={interactive}
        eventHandlers={
          {
            click: () => { 
              if(onClick!=null) onClick(renderedPosition);
            }
          }
        }
        icon={myPositionIcon} >
        <Popup>
          {
            boatInfo.BOAT_NAME != null
            && <div>{boatInfo.BOAT_NAME}</div>
          }
          <div>{boatInfo.MMSI}</div>
          { t('your_position')}
        </Popup>
      </Marker>
    </>
  )
}

import { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack } from "react-bootstrap";
import { useInfo } from "../contexts/InfoProvider";
import { useWidth } from "../contexts/WidthProvider";
import { useTheme } from "../contexts/ThemeProvider";
import { useTranslation } from "react-i18next";
import { useBoatInfo } from "../contexts/BoatInfoProvider";
import { useMap } from "../contexts/MapProvider";
import { useLocation } from "../contexts/LocationProvider";
import { useShips } from "../contexts/ShipsProvider";
import SettingsModal from "../components/SettingsModal";
import Footer from "../components/Footer";

import logo from "../img/new/iside-logo.png";
import logo_dark from "../img/new/iside-logo-dark.png";
import settings_icon from "../img/icons/settings_blu.png";
import settings_icon_dark from "../img/icons/settings_white.png";
import settings_icon_yellow from "../img/icons/settings_yellow.png";
import map_icon from "../img/icons/map_blu.png";
import map_icon_dark from "../img/icons/map_white.png";
import map_icon_yellow from "../img/icons/map_yellow.png";
import daynight_icon from "../img/icons/daynight_blu.png";
import daynight_icon_dark from "../img/icons/daynight_white.png";
import daynight_icon_yellow from "../img/icons/daynight_yellow.png";
import iside_icon from "../img/new/iside-logo-ships.png";

function Header() {
  const { t } = useTranslation();
  const { useMobileLayout } = useWidth();
  const { isDarkTheme, toggleTheme } = useTheme();
  const { OpenInfoModal } = useInfo();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { boatInfo } = useBoatInfo();
  const { OpenMapModal } = useMap();
  const { coords } = useLocation();
  const { selectShipMmsi } = useShips();
  
  function closeSettingsModal() {
    setOpenSettingsModal(false);
  }

  return (
    <header >
      {useMobileLayout ?
        <Stack direction="vertical">
        <Stack direction="horizontal" className="header-content">
          <div className="header-logo">
            <img src={isDarkTheme ? logo_dark : logo} alt={t("project_iside")} onClick={() => { OpenInfoModal() }} />
          </div>
          <FontAwesomeIcon
            className="ms-auto menu"
            icon="bars"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          />
          </Stack>
          {showMobileMenu &&
            <>
            <div className="header-menu mobile">
              <div>
                <div className="boat-info">
                  <div className="boat-img">
                    <img src={iside_icon} alt="" />
                  </div>
                  <div className="boat-info-text">
                    { boatInfo.BOAT_NAME != null && <span>{boatInfo.BOAT_NAME}</span> }
                    { boatInfo.MMSI != null && <span>{boatInfo.MMSI}</span> }
                  </div>
                  <div
                    className="close-button"
                    onClick={() => {
                      setShowMobileMenu(false);
                    }}>
                    <FontAwesomeIcon
                      icon="times" />
                  </div>
                </div>
                <div className="header-button"
                  onClick={() => {
                    setShowMobileMenu(false);
                    setOpenSettingsModal(true)
                  }}>
                  <img src={isDarkTheme ? settings_icon_yellow : settings_icon} alt={t("settings")}></img>
                  <span>{t("settings")}</span>
                </div>
                <div className="header-button"
                  onClick={() => {
                    setShowMobileMenu(false);
                    OpenMapModal({
                      initialPosition: coords,
                      interactive: true,
                      callback: (selectedShip) => {
                        if (selectedShip != null && selectedShip.mmsi != null) {
                          selectShipMmsi(selectedShip.mmsi);
                        }
                      }
                    })
                  }}>
                  <img src={isDarkTheme ? map_icon_yellow : map_icon} alt={t("map")}></img>
                  <span>{t("map")}</span>
                </div>
                <div className="header-button"
                  onClick={() => {
                    setShowMobileMenu(false);
                    toggleTheme()
                  }}>
                  <img src={isDarkTheme ? daynight_icon_yellow : daynight_icon} alt={t("day_night")}></img>
                  <span>{t("day_night")}</span>
                </div>
              </div>
              <Footer className/>
              </div>
            </>
          }
          </Stack>
        :
        <Stack direction="horizontal" className="header-content">
          <div className="header-logo desktop">
            <img className="header-content" src={isDarkTheme ? logo_dark : logo} alt={t("project_iside")} onClick={() => { OpenInfoModal() }} />
          </div>
          <div className="header-menu">
            <div className="header-button" onClick={() => setOpenSettingsModal(true)}>
              <img src={isDarkTheme ? settings_icon_dark : settings_icon} alt={t("settings")}></img>
              <span>{t("settings")}</span>
            </div>
            <div className="header-button" onClick={() => OpenMapModal({
              initialPosition: coords,
              interactive: true,
              callback: (selectedShip) => {
                if (selectedShip != null && selectedShip.mmsi != null) {
                  selectShipMmsi(selectedShip.mmsi);
                }
              }
            })}>
              <img src={isDarkTheme ? map_icon_dark : map_icon} alt={t("map")}></img>
              <span>{t("map")}</span>
            </div>
            <div className="header-button" onClick={() => { toggleTheme() }}>
              <img src={isDarkTheme ? daynight_icon_dark : daynight_icon} alt={t("day_night")}></img>
              <span>{t("day_night")}</span>
            </div>
          </div>
          <div className="ms-auto boat-info">
            <div className="boat-info-text">
              { boatInfo.BOAT_NAME != null && <span>{boatInfo.BOAT_NAME}</span> }
              { boatInfo.MMSI != null && <span>{boatInfo.MMSI}</span> }
            </div>
            <div className="boat-img">
              <img src={iside_icon} alt="" />
            </div>
          </div>
        </Stack>
      }
      <SettingsModal
      show={openSettingsModal}
      onHide={closeSettingsModal}
      />
    </header>
  );
}

Header.propTypes = {
  leftButton: PropTypes.element,
  rightButton: PropTypes.element,
};

export default Header;

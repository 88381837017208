import React, { useEffect, useState } from 'react'
import { Polyline } from 'react-leaflet'
import { useLocation } from '../../contexts/LocationProvider';
import { useShips } from "../../contexts/ShipsProvider";

export default function BoatConnectionLine({ startPosition, selectedShip }) {
  const { coords } = useLocation();
  const { unfilteredShips } = useShips();
  const _startPosition = coords != null ? coords : startPosition;
  const [endPosition, setEndPosition] = useState();

  useEffect(() => {
    if (unfilteredShips != null && selectedShip != null) {
      let ship = unfilteredShips.find((s) => s.mmsi === selectedShip.mmsi);
      if (ship == null) {
        setEndPosition(null);
      } else {
        setEndPosition((previousPosition) => {
          if (previousPosition == null || previousPosition[0] !== ship.latitude || previousPosition[1] !== ship.longitude) {
            return [ship.latitude, ship.longitude];
          }
          return previousPosition;
        });
      }
    }
  }, [unfilteredShips, selectedShip, setEndPosition])

  return (
    <>
      { _startPosition != null && endPosition != null ?
        <Polyline
          positions={[
            _startPosition,
            endPosition
          ]}
          color={'red'}
          dashArray={'5,10'} />
        : <></>
      }
    </>
  )
}

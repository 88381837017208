import React from 'react'
import { useInfo } from '../contexts/InfoProvider';
import { useWidth } from '../contexts/WidthProvider';
import interreg_logo from '../img/new/interreg-logo.png';

export default function Footer(props) {
  const { OpenInfoModal } = useInfo();
  const { useMobileLayout } = useWidth();

  return (
    <footer className='p-2' style={{ ...props.style }}>
      <img className={`d-block h-100 ${useMobileLayout ? "m-auto" : "ms-auto"}`} src={interreg_logo} alt="Interreg" onClick={() => { OpenInfoModal() }}/>
    </footer>
  )
}

import React, { useEffect } from 'react'
import CustomMapMarker from './CustomMapMarker';
import { useShips } from "../../contexts/ShipsProvider";

export default function MapMarkers({ isInteractive, selectShip, selectedShip, popupRef }) {
  const { unfilteredShips } = useShips();

  useEffect(() => {
    if (unfilteredShips != null && selectedShip != null && popupRef != null && popupRef.current != null) {
      let ship = unfilteredShips.find((s) => s.mmsi === selectedShip.mmsi);
      if (ship == null) {
        selectShip(null);
      } else {
        let popupPosition = popupRef.current.getLatLng();
        if (popupPosition != null && ship.latitude !== popupPosition.lat
          && ship.longitude !== popupPosition.lng) {
          popupRef.current.setLatLng([ship.latitude, ship.longitude]);
        }
      }
    }
  }, [unfilteredShips, selectedShip, selectShip, popupRef])

  return (
    <>
      {
        unfilteredShips.map((ship, index) => {
          if(ship.latitude!=null && ship.longitude!=null){
            return (
              <CustomMapMarker
                key={index}
                onClick={selectShip}
                ship={ship}
                isSelected={selectedShip != null && ship.mmsi === selectedShip.mmsi}
                isInteractive={isInteractive} />)
          }
          return null
        })
      }
    </>
  )
}

import React, { useState, useRef } from 'react'
import { Modal, Stack } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, Popup,useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import VectorTileLayer from 'react-leaflet-vector-layer';
import { DivIcon } from 'leaflet'
import ReactDOMServer from 'react-dom/server';
import MapMarkers from './MapMarkers';
import MyPositionMarker from './MyPositionMarker';
import { useMap } from '../../contexts/MapProvider';
import BoatConnectionLine from './BoatConnectionLine';
import { useTheme } from '../../contexts/ThemeProvider';

import map_icon from "../../img/icons/selectposition_blu.png";
import map_icon_dark from "../../img/icons/selectposition_white.png";

export default function MapModal({ closeModal, options }) {
  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedShip, setSelectedShip] = useState(options.initialSelection);
  const popupRef = useRef();
  const { USE_OFFLINE_MAP,MAP_SERVER_URL,DEFAULT_POSITION} = useMap();
  
  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setSelectedPosition([
          e.latlng.lat,
          e.latlng.lng
        ]); 
      },
    });
    return false;
  }
  
  const selectShip = (ship) => {
    setSelectedShip(ship)
  }

  const selectionIconHTML = ReactDOMServer.renderToString(
    <FontAwesomeIcon
      icon='map-marker-alt'
      color='red'
      style={{ width: "24px", height: "40px" }} />
  );

  const selectionIcon = new DivIcon({
    html: selectionIconHTML,
    iconSize: [24, 40], 
    iconAnchor: [12, 40]
  });

  return (
    <>
      <Modal.Header closeButton closeVariant={isDarkTheme ? "white" : null}>
        <div className='title'>
          <div className="title-icon">
            <img src={isDarkTheme ? map_icon_dark : map_icon} alt="" />
          </div>
          <div className="title-text">
            {selectedShip != null
              ? (selectedShip.name != null
                ? selectedShip.name : selectedShip.mmsi)
              : (options != null && options.isInteractive === true
                ? t('select_boat') : t('select_position'))
            }
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='overflow-auto'>
          <MapContainer
            center={
              options.initialBounds == null ?
                (options.initialPosition != null ? options.initialPosition : DEFAULT_POSITION)
                : null}
            zoom={
              options.initialBounds == null ?
                (options.initialPosition != null ? 15 : 7)
                : null}
            bounds={options.initialBounds}
            boundsOptions={{padding: [50, 50]}}
            scrollWheelZoom={false}
            style={{ height: '65vh', width: '100%' }}>
            {
              USE_OFFLINE_MAP
              ? <VectorTileLayer styleUrl={`${MAP_SERVER_URL}/styles/iside/style.json`} />
              : <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            }
            <MyPositionMarker
              defaultPosition={options.initialPosition != null ? options.initialPosition : DEFAULT_POSITION}
              onClick={(position) => {
                if (!options.isInteractive) {
                  setSelectedPosition(position);
                }
              }}
            />
            <MapMarkers
              isInteractive={options.isInteractive}
              selectShip={selectShip}
              selectedShip={selectedShip}
              popupRef={popupRef}
            />
            {
              !options.isInteractive && selectedPosition
              && <Marker           
                key={selectedPosition[0]}
                position={selectedPosition}
                interactive={false} 
                icon= {selectionIcon}   
              />
            }
            { selectedShip != null
              && <>
              <BoatConnectionLine
                selectedShip={selectedShip}
                startPosition={options.initialPosition != null ? options.initialPosition : DEFAULT_POSITION}
                 />
                <Popup
                  ref={popupRef}
                  position={[selectedShip.latitude, selectedShip.longitude]}
                  onClose={() => selectShip(null)}>
                  {
                    selectedShip.name != null
                    && <div>{selectedShip.name}</div>
                  }
                  <div>{selectedShip.mmsi}</div>
                  {
                    selectedShip.cargo != null
                    && <div>{selectedShip.cargo}</div>
                  }
                  {
                    selectedShip.navStatus != null
                    && <div>{selectedShip.navStatus}</div>
                  }
                </Popup>
              </>
            }
            {
              !options.isInteractive
              && <MapEvents />
            }
          </MapContainer>         
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Stack
          direction="horizontal"
          gap="2"
          className="">
          <div
            className="modal-button"
            onClick={() => closeModal(null)}>
            {t('cancel')}
          </div>
          {
            options.isInteractive
            ? <div
                className="modal-button"
                disabled={selectedShip == null}
                onClick={() => closeModal(selectedShip)}>
                {t('select_boat').toUpperCase()}
              </div>
            : <div
                className="modal-button"
                disabled={selectedPosition == null
                     || selectedPosition[0] == null
                     || selectedPosition[1] == null}
                onClick={() => closeModal(selectedPosition)}>
                {t('confirm_text')}
              </div>
          }
        </Stack>
      </Modal.Footer>
    </>
  )
}

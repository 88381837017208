import { useTranslation } from "react-i18next";
import Message from "./Message"

export default function MessageList({ messages, shipName, isBaseStation, iside }) {
  const { t } = useTranslation();

  return (
    <div className="message-list">
      {messages === null || messages === undefined ?
        <div>{t("no_messages")}</div>
        :
        messages.map((message, index) => {
          const lastMessage = messages.length - 1 === index;
          return (
            <Message
              key={index}
              isLast={lastMessage}
              message={message}
              shipName={shipName}
              isBaseStation={isBaseStation}
              iside={iside}
            />
          );
        })
      }
    </div>
  )
}

import { Container } from "react-bootstrap";
import Home from "./pages/Home";
import Empty from "./pages/Empty";
import Conversation from "./pages/Conversation";
import { useSocket } from "./contexts/SocketProvider"
import { useShips } from "./contexts/ShipsProvider";
import { useWidth } from "./contexts/WidthProvider";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useTranslation } from "react-i18next";

function App() {
  const { isConnected } = useSocket();
  const { selectedShip } = useShips();
  const { useMobileLayout } = useWidth();
  const { t } = useTranslation();

  return (
    <Container
      className="px-0"
      style={{ height: "100vh", maxWidth: "100vw" }}>
      <Header />
      {
        !isConnected ?
          <div className="main-content w-100 text-center">
            <Empty message={"Connecting..."} />
          </div>
          :
          (useMobileLayout)?
            <div className="main-content mobile w-100 text-center">
            {selectedShip == null ?
              <Home />
              :
              <Conversation ship={selectedShip} />}
            </div>
          :
            <div className="main-content w-100 text-center d-flex">
            {selectedShip == null ?
            <>
              <Home />
              <Empty message={t('select_a_ship')} />
            </>   
              :
              <>
                <Home />
                <Conversation ship={selectedShip} />
              </>}
          </div>
          
      }
      {(!isConnected || !useMobileLayout) && <Footer />}
    </Container>
  );
}

export default App;

import { useMap} from 'react-leaflet'
import {useEffect} from 'react'

export default function ChangeView({ bounds }) {
  const map = useMap();
  useEffect(() => {
    try {
      if (map != null && bounds != null) {
        if (bounds._southWest.lat === bounds._northEast.lat && bounds._southWest.lng === bounds._northEast.lng) {
          const Leaflet = window.L;
          let center = Leaflet.latLng([bounds._southWest.lat, bounds._southWest.lng]);
          map.invalidateSize();
          map.setView(center, 15);
        } else {
          map.invalidateSize();
          map.fitBounds(bounds, { padding: [25, 25] })
        }
      }
    } catch (e) {
      console.error("Error in ChangeView", e);
    }
  }, [map, bounds])
  return null;
}


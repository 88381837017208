import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap"
import { useFrasario } from "../contexts/FrasarioProvider";
import MessageCard from './MessageCard';
import { useTranslation } from 'react-i18next';
import SearchBar from "../components/SearchBar";
import { useTheme } from '../contexts/ThemeProvider';

import back_icon from "../img/icons/left.png";
import back_icon_dark from "../img/icons/left_white.png";
import text_icon from "../img/icons/text_blu.png";
import text_icon_dark from "../img/icons/text_yellow.png";
import reply_icon from "../img/icons/reply.png";
import reply_icon_dark from "../img/icons/reply_white.png";

export default function NewAdvancedMessageModal({ closeModal, selectedMessage }) {
  const isNewMessage =
  selectedMessage == null ||
  selectedMessage.id == null ||
  selectedMessage.id === "NEW";
  const { frasario,parseMessage } = useFrasario();
  const { t, i18n } = useTranslation();
  const { isDarkTheme } = useTheme();
  const [messageFilter, setMessageFilter] = useState("");
  const language=t('language').toUpperCase();
  const parsedMessage = isNewMessage ? null : parseMessage(selectedMessage);
  console.log(parsedMessage)
  const modalTitle= parsedMessage!=null && (parsedMessage.isReply || parsedMessage.id === "CUSTOM")?parsedMessage.text:t('new_message_label');
  const [onlyCustom, setOnlyCustom] = useState(false);


  const [stack, setStack] = useState([{children: [{id: "CUSTOM", noIcon:true, edit: false}]}]);


  useEffect(()=>{

    setStack(()=>{
      if(selectedMessage!=null&&selectedMessage.id==="CUSTOM"){

        setOnlyCustom(true);
        return [{children: [{id: "CUSTOM", noIcon:true, edit: true}]}]
      }
      let messageArray= Object.entries(frasario.MESSAGES);
      let filtered= messageArray.filter(([key,value]) => {
        return !key.startsWith("C")&&key!=="TEST" 
      }).filter(([key,value])=>{
        if (messageFilter == null || messageFilter === "" ) return true;
        let lang= language||i18n.language||"EN";
        return value.text[lang!=null?lang.toUpperCase():"EN"].toLowerCase().includes(messageFilter.toLowerCase());
      }).map(([key,value])=> {  
        if(value.params!=null) {
          for (let i = 0; i < value.params.length; i++) {
            const param = value.params[i];
            if(param.type!=="BOAT_INFO") {
              return {id:key, hasParam:true}
            } 
          }
          return {id:key}
        }
        return{id:key}})
        
      return [{children: [{id: "CUSTOM", noIcon:true, edit: false}, ...filtered]}]
    })
  },[messageFilter,setStack, frasario.MESSAGES,language,selectedMessage, i18n.language])
 
  function handleMessageClick(message) {
    //console.log(message)
    if (message == null) {
      closeModal(null);
      return;
    }
    if (message.id === "CUSTOM") {
      setStack([...stack, {  isCustom: true, children: [{ ...message, edit: true }] }])}
    else if(message.hasParam){setStack([...stack, { children: [{id:message.id}]}])}
    else if (message.children != null) {
      setStack((previousStack) => {
        return [...previousStack, { children: message.children }];
      })
    }
  }

  function handleBackClick() {
    if (stack.length > 1) {
      let newStack = [...stack];
      newStack.pop();
      setStack(newStack);
    } else {
      closeModal(null);
    }
  }

  return (
    <>
      <Modal.Header closeButton closeVariant={isDarkTheme ? "white" : null}>
        {stack != null && stack.length > 1 &&
          <div className='button' onClick={handleBackClick}>
            <img src={isDarkTheme ? back_icon_dark : back_icon} alt={t("back")} />
          </div>
        }
        <div className="title">
          {selectedMessage != null && selectedMessage.isReply ?
            <div className="title-icon">
              <img src={isDarkTheme ? reply_icon_dark : reply_icon} alt="" />
            </div>
            :
            stack.length === 1 && onlyCustom ?
              <div className="title-icon small">
                <img src={isDarkTheme ? text_icon_dark : text_icon} alt="" />
              </div>
              :
              null
          }
          <div className="title-text">
            {stack == null ? '' : modalTitle}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {stack.length<=1&&!onlyCustom&&<div className="pb-4">
          <SearchBar placeholder={t('search_message')} searchQuery={messageFilter} setSearchQuery={setMessageFilter} />
        </div>}
        <div className="body advanced">
          {
            stack != null &&
            stack[stack.length - 1].children != null &&
            stack[stack.length - 1].children.map(
              (message, index) => {
                return <MessageCard
                  key={index}
                  onClick={handleMessageClick}
                  onSubmit={closeModal}
                  message={message}
                />;
              }
            )
          }
        </div>
      </Modal.Body>
    </>
  )
}

import React from 'react'
import { Modal } from 'react-bootstrap'

export default function UserConfirmModal({onConfirm, onCancel, title, text, confirmButtonText, cancelButtonText}) {
  return (
    <>
      <Modal.Header>
        <div className='title'>
          <div className="title-text">
            {title}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h5 className="body">
          {text}
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="modal-button secondary"
          onClick={onCancel}>
          {cancelButtonText}
        </div>
        <div
          className="modal-button danger"
          onClick={onConfirm}>
          {confirmButtonText}
        </div>
      </Modal.Footer>
    </>
  )
}

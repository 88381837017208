// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/CircularStd-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/CircularStd-500.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../fonts/CircularStd-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"CircularStd\";\n  src: local(\"CircularStd\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"CircularStd\";\n  font-weight: 500;\n  src: local(\"CircularStd\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"CircularStd\";\n  font-weight: bold;\n  src: local(\"CircularStd\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n\nbody, html {\n  font-family: 'CircularStd';\n}", "",{"version":3,"sources":["webpack://src/css/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,qFAAqF;AACvF;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,qFAAiF;AACnF;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,qFAAkF;AACpF;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["@font-face {\n  font-family: \"CircularStd\";\n  src: local(\"CircularStd\"), url(\"../fonts/CircularStd-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"CircularStd\";\n  font-weight: 500;\n  src: local(\"CircularStd\"), url(\"../fonts/CircularStd-500.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"CircularStd\";\n  font-weight: bold;\n  src: local(\"CircularStd\"), url(\"../fonts/CircularStd-Bold.ttf\") format(\"truetype\");\n}\n\nbody, html {\n  font-family: 'CircularStd';\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import {isMobile} from 'react-device-detect';

const WidthContext = React.createContext();

export function useWidth() {
  return useContext(WidthContext);
}
  
export function WidthProvider({ children }) {
    const [width, setWidth] = useState(window.innerWidth);
    const [useMobileLayout,setMobileLayout] = useState(isMobile || window.innerWidth <= 768);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setMobileLayout(isMobile || window.innerWidth <= 768);
      }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
        }
      }, []);
      
  const value = {
    useMobileLayout,
    width
  }

  return (
    <WidthContext.Provider value={value}>
      {children}
    </WidthContext.Provider>
  )
}

import { Container, Modal } from "react-bootstrap";
import { useConversations } from "../contexts/ConversationsProvider";
import { useShips } from "../contexts/ShipsProvider";
import MessageList from "../components/MessageList";
import NewMessageModal from "../components/NewMessageModal";
import NewAdvancedMessageModal from "../components/NewAdvancedMessageModal";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useWidth } from '../contexts/WidthProvider';
import { useSettings } from '../contexts/SettingsProvider';
import { useTheme } from "../contexts/ThemeProvider";
import BoatInfo from "../components/BoatInfo";

import back_icon from "../img/icons/left.png";
import back_icon_dark from "../img/icons/left_yellow.png";
import info_icon from "../img/icons/info.png";
import info_icon_dark from "../img/icons/info_white.png";
import info_icon_yellow from "../img/icons/info_yellow.png";
import reply_icon from "../img/icons/reply.png";
import mayday_icon from "../img/icons/mayday.png";
import panpan_icon from "../img/icons/panpan.png";
import securite_icon from "../img/icons/securite.png";
import new_message_icon from "../img/icons/panpan_more.png";
import text_icon from "../img/icons/text_blu.png";
import text_icon_dark from "../img/icons/text_yellow.png";

export default function Conversation({ ship }) {
  const { t } = useTranslation();
  const { isDarkTheme } = useTheme();
  const {useMobileLayout} = useWidth();
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [lastReceivedMessage, setLastReceivedMessage] = useState(null);
  const { selectShipMmsi } = useShips();
  const { onlyIsideMessage, advancedMessaging }= useSettings();
  const [openInfoBoat, setOpenInfoBoat] = useState(false);


  function closeInfoBoat() {
    setOpenInfoBoat(false);
  }

  const {
    conversations,
    sendMessage,
    isLoading: isLoadingConversations
  } = useConversations();


  function closeModal(message) {
    if (message != null) {
      // Sending message to recipient ship
      sendMessage( ship.mmsi, message)
    }
    setSelectedMessage(null);
  }

  useEffect(() => {
    let receivedMessage = null;
    if (conversations[ship.mmsi] != null
      && conversations[ship.mmsi].messages != null
      && conversations[ship.mmsi].messages.length > 0) {
      const messages = [...conversations[ship.mmsi].messages];
      for (let i = messages.length - 1; i >= 0; i--) {
        if (messages[i].received === true) {
          receivedMessage = messages[i];
          break;
        }
      }
    } //else if(useMobileLayout) {
    //  setSelectedMessage({ id: "NEW" });
    // }
    setLastReceivedMessage(receivedMessage);
  }, [conversations, ship.mmsi])

  useEffect(() =>{
    setOpenInfoBoat(useMobileLayout?false:true)
  }, [useMobileLayout, ship.mmsi])

  return (
    <div
      className="d-flex align-items-start flex-grow-1 h-100">
      {(!useMobileLayout || !openInfoBoat) &&
        <Container
          className="Conversation">
          <div className={`panel-header ${useMobileLayout ? "mobile" : ""}`}>
            {useMobileLayout &&
              <div className="button" onClick={() => selectShipMmsi(null)}>
                <img src={isDarkTheme ? back_icon_dark : back_icon} alt={t("back")} />
              </div>
            }
            <div className="text">
              {t("to")}: <span className="ship-name">{ship.name != null ? ship.name : ship.mmsi}</span>
            </div>
            {!openInfoBoat &&
              <div className="button" onClick={() => setOpenInfoBoat(true)}>
                <img src={isDarkTheme ? (useMobileLayout ? info_icon_yellow : info_icon_dark) : info_icon} alt={t("boat_info")} />
              </div>
            }
          </div>
          {isLoadingConversations ?
            <div className="empty">{t('loading')}</div>
            :
            <>
              <div className="content overflow-auto">
                <div className={`${!useMobileLayout ? "limited-width" : ""}`}>
                  {
                    conversations[ship.mmsi] != null && conversations[ship.mmsi].messages != null &&
                    <MessageList
                      messages={conversations[ship.mmsi].messages}
                      shipName={conversations[ship.mmsi].shipName}
                      isBaseStation={ship.isBaseStation}
                      iside={ship.iside}
                    />
                  }
                </div>
              </div>
              {!ship.outOfRange && (useMobileLayout ||
                (conversations[ship.mmsi] != null &&
                conversations[ship.mmsi].messages != null &&
                conversations[ship.mmsi].messages.length > 0)) && <div className={`conversation-buttons ${useMobileLayout ? "mobile" : ""}`}>
                <div className={`${!useMobileLayout ? "limited-width" : ""}`}>
                  {lastReceivedMessage != null ?
                    <div
                      className="conversation-button reply-button"
                      onClick={() => setSelectedMessage({ ...lastReceivedMessage, isReply: true })}>
                      <img src={reply_icon} alt={t('answer')} />
                      <span>{t('answer')}</span>
                    </div>
                    :
                    <div></div>
                  }
                  <div className="new-message-buttons">
                    {(!onlyIsideMessage || ship.iside) &&
                      <>
                        {!advancedMessaging ?
                          <>
                            <div
                              className="conversation-button"
                              onClick={() => setSelectedMessage({ id: "C1" })}>
                              <img src={mayday_icon} alt={t("may_day")} />
                              <span>{t("may_day")}</span>
                            </div>
                            <div
                              className="conversation-button"
                              onClick={() => setSelectedMessage({ id: "C2" })}>
                              <img src={panpan_icon} alt={t("pan_pan")} />
                              <span>{t("pan_pan")}</span>
                            </div>
                            <div
                              className="conversation-button"
                              onClick={() => setSelectedMessage({ id: "C3" })}>
                              <img src={securite_icon} alt={t("securite")} />
                              <span>{t("securite")}</span>
                            </div>
                          </>
                          :
                          <div
                            className="conversation-button"
                            onClick={() => setSelectedMessage({ id: "NEW" })}>
                            <img src={new_message_icon} alt={t("new_message_label").toUpperCase()} />
                            <span>{t("new_message_label").toUpperCase()}</span>
                          </div>
                        }
                      </>
                    }
                    <div
                      className="conversation-button text-button"
                      onClick={() => setSelectedMessage({ id: "CUSTOM", text: t("custom_text") })}>
                      <img src={isDarkTheme ? text_icon_dark : text_icon} alt={t("text").toUpperCase()} />
                      <span>{t("text").toUpperCase()}</span>
                    </div>
                  </div>
                </div>
              </div>}
            </>
        }
        </Container>
      }
      {openInfoBoat && <BoatInfo ship={ship} onClose={closeInfoBoat}></BoatInfo>}
      <Modal
        className={`${!useMobileLayout ? "desktop" : ""}`}
        show={selectedMessage != null}
        onHide={closeModal}
        centered>
        {!advancedMessaging ?
          <NewMessageModal
            closeModal={closeModal}
            selectedMessage={selectedMessage} />
          :
          <NewAdvancedMessageModal
            closeModal={closeModal}
            selectedMessage={selectedMessage} />
        }
      </Modal>
    </div>
  )
}

import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../hooks/useLocalStorage';

const DEFAULT_SETTINGS = {
  speechEnabled: false,
  language: "en",
  onlyIsideMessage: true,
  advancedMessaging: false,
  useInternet: false,
  aistype: 12,
  aisStatus: true
}

const SettingsContext = React.createContext();

export function useSettings() {
  return useContext(SettingsContext);
}

export function SettingsProvider({ children }) {
  const { i18n }= useTranslation();
  const [settings, setSettings] = useLocalStorage("settings", {...DEFAULT_SETTINGS});

  useEffect(() => {
    if (settings == null) return;
    if (settings["language"] != null
      && settings["language"] !== i18n.language) {
      // console.log(`Setting app language to: ${settings["language"]}`);
      i18n.changeLanguage(settings["language"]);
    }
  }, [settings, i18n])
  
  const updateSettings = (key, value) => {
    if (settings[key] !== value) {
      setSettings(prevSettings => {
        return { ...prevSettings, [key]: value };
      })
    }
  }
  
  const value = {
    speechEnabled: settings["speechEnabled"], 
    setSpeechEnabled: (value) => updateSettings("speechEnabled", value),
    language: settings["language"],
    setLanguage: (value) => updateSettings("language", value),
    onlyIsideMessage: settings["onlyIsideMessage"],
    setOnlyIsideMessage: (value) => updateSettings("onlyIsideMessage", value),
    advancedMessaging: settings["advancedMessaging"],
    setAdvancedMessaging: (value) => updateSettings("advancedMessaging", value),
    useInternet: settings["useInternet"],
    setUseInternet: (value) => updateSettings("useInternet", value),
    aistype: settings["aistype"],
    setAistype: (value) => updateSettings("aistype", value),
    supportedAistypes: [6, 12],
    aisStatus: settings["aisStatus"],
    setAisStatus: (value) => updateSettings("aisStatus", value),
    resetSettings: () => setSettings({...DEFAULT_SETTINGS})
  }
  
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}

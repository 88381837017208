import { useFrasario } from "../contexts/FrasarioProvider"
import { useWidth } from "../contexts/WidthProvider";
import CustomMessageForm from "./CustomMessageForm";
import MessageForm from "./MessageForm";

export default function MessageCard({ message, onClick, onSubmit }) {
  const isCustom = message != null && message.id === "CUSTOM" && message.edit;
  const { frasario, parseMessage } = useFrasario();
  const parsedMessage = parseMessage(message, true);
  const maskedMessage = message!=null&&message.hasParam?parseMessage(message,true,true):null;
  const messageText = maskedMessage!=null?maskedMessage.text: (parsedMessage!=null?parsedMessage.text:"");
  const frasarioMessage = frasario.MESSAGES[message.id];
  const hasEditableFields = frasarioMessage.params != null && frasarioMessage.params.reduce((prev, current) => prev || current.type !== "BOAT_INFO", false);
  const isLeaf = !isCustom && (frasarioMessage.params == null || message.hasParam || !hasEditableFields);
  const { useMobileLayout } = useWidth();

  function handleClick(clickedMessage) {
    if (clickedMessage == null || clickedMessage.id == null) {
      onSubmit(null);
      return;
    }
    if (clickedMessage.id === "CUSTOM" ||clickedMessage.hasParam || clickedMessage.children != null) {
      onClick(clickedMessage);
      return;
    } else {
      onSubmit({ id: clickedMessage.id });
    }
  }
  return (
    <div className={`message-card ${useMobileLayout ? "mobile" : ""}`}>
      <div className="message-card-icon">
        {parsedMessage.icon &&
          <img src={`./images/icons/${parsedMessage.icon}.png`} alt="" />
        }
      </div>
      <div className={`message-card-body ${isLeaf ? "leaf" : ""}`}>
        {
          isCustom ?
            <CustomMessageForm message={parsedMessage} onSubmit={onSubmit}/>
          :
            frasarioMessage.params != null && !message.hasParam ?
              <MessageForm message={parsedMessage} params={frasarioMessage.params} onSubmit={onSubmit} />
            :
              <div onClick={() => handleClick(parsedMessage)}>
                {/* {
                  parsedMessage.id === "CUSTOM" &&
                  !parsedMessage.icon && !parsedMessage.noIcon &&
                  <div
                      className="d-flex justify-content-center align-items-center mx-3 fw-bold"
                    style={{ width: "50px", height: "50px", fontSize: "50px", fontFamily: "serif"}}>
                    T
                  </div>
                } */}
                {messageText}
              </div>
        }
      </div>
    </div>
  )
}

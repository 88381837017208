import {Container} from "react-bootstrap";

export default function Empty({message}) {
    return (
        <Container
            className="Conversation">
            <div className="empty">
                <h1 className="text-accent">
                    {message != null ? message : ""}
                </h1>
            </div>
        </Container>
    );
}

import React, { useContext, useState, useEffect } from 'react'

const THEMES = {
  dark: "dark",
  light: "",
};

const ThemeContext = React.createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(THEMES.light);

  useEffect(() => {
    switch (theme) {
      case THEMES.dark:
        document.body.setAttribute("data-theme", THEMES.dark);
        break;
      case THEMES.light:
      default:
        document.body.setAttribute("data-theme", THEMES.light);
        break;
    }
  }, [theme]);

  function toggleTheme() {
    switch (theme) {
      case THEMES.dark:
        setTheme(THEMES.light);
        break;
      case THEMES.light:
      default:
        setTheme(THEMES.dark);
        break;
    }
  }
  
  const value = {
    isDarkTheme: theme === THEMES.dark,
    toggleTheme
  }

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  )
}

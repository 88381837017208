import React, { useContext } from 'react'
import TTS from 'text-to-speech-offline'
import { useSettings } from './SettingsProvider';

const SpeechContext = React.createContext();

export function useSpeech() {
  return useContext(SpeechContext);
}

export function SpeechProvider({ children }) {
  const { speechEnabled } = useSettings();

  function speak (text, language) {
    if(speechEnabled)return TTS(text,language);
  }
  
  const value = {
    speak
  }

  return (
    <SpeechContext.Provider value={value}>
      {children}
    </SpeechContext.Provider>
  )
}

import { useConversations } from "../contexts/ConversationsProvider";
import Ship from "./Ship";

function ShipList({ ships }) {
  const { conversations } = useConversations();
  return (
    <>
      {
        ships === null || ships === undefined ? (
          <div>No Ships Nearby</div>
        ) : (
          ships.map((ship) => (
            <Ship 
              key={ship.mmsi} 
              ship={ship}
              shipName={conversations[ship.mmsi] != null ? conversations[ship.mmsi].shipName : null} 
              hasMessages={conversations[ship.mmsi] != null && conversations[ship.mmsi].messages != null && conversations[ship.mmsi].messages.length > 0}
              unreadMessages={conversations[ship.mmsi] != null && conversations[ship.mmsi].unreadMessages != null ? conversations[ship.mmsi].unreadMessages : 0}
            />
          ))
        )
      }
    </>
  );
}

export default ShipList;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Marker } from 'react-leaflet'
import { DivIcon } from 'leaflet'
import ReactDOMServer from 'react-dom/server';

export default function CustomMapMarker({ ship, isInteractive, onClick, isSelected }) {
  const iconHTML = ship.isBaseStation
    ? ReactDOMServer.renderToString(<FontAwesomeIcon icon='anchor' color='red' style={{ width: "24px", height: "24px" }} />)
    : ship.heading != null && ship.heading !== 511
      ? ReactDOMServer.renderToString(<FontAwesomeIcon icon='location-arrow' color={isSelected ? 'red' : '#1976D2'} transform={{ rotate: ship.heading - 45 }} style={{ width: "24px", height: "24px" }} />)
      : ReactDOMServer.renderToString(<FontAwesomeIcon icon='location-arrow' color={isSelected ? 'red' : '#757575'} transform={{ rotate: -45 }} style={{ width: "24px", height: "24px" }} />);
  return (
    <Marker           
      position={[ship.latitude, ship.longitude]}
      interactive={isInteractive}
      eventHandlers={
        {
          click: onClick!=null?() => {
            onClick(ship);
          }:null
        }
      }
      icon= {new DivIcon({
        html: iconHTML,
        iconSize: [24, 24], 
        iconAnchor: [12, 12]
      })}>
      
    </Marker>
  )
}
